@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap')

$base-color: #C49545


*   
    color: #fff
    padding: 0
    margin: 0
    font-size: 62.5% // 1rem = 10px
    scroll-behavior: smooth



