$base-color: #C49545
$details-color: #D6AF4F

#pageServices
    max-width: 192rem
    height: 100%  
    background-color: $base-color

    .imageServices
        height: 409px
        width: 100%
        background: url('../../image/imageServices.png')
        background-repeat: no-repeat
        background-position: center
        display: flex
        justify-content: center
    
    .outConfig
        height: 100%
        width: 100%
        display: flex
        justify-content: center

    .config
        width: 70%
        
    .header 
        display: flex  
        justify-content: space-between  

        :first-child
            z-index: 50
            cursor: pointer

    .hamburguer
        display: none

    .hamburguerClose
        display: none

    .navigation, .navigationCascata
        display: flex
        align-items: center
        width: 80%
        justify-content: space-between 
        a   
            font-weight: 500
            font-size: 2rem
            font-family: 'Inter'
            text-decoration: none
            color: white
            padding: 2rem
            
        .buttonLogin
            border: 1px solid $details-color
            border-radius: .8rem
            padding: .5rem 3rem .5rem 3rem
            background: none
            font-weight: 500
            font-size: 2rem
            font-family: 'Inter'

    .textServices
        :first-child
            font-family: 'Josefin Sans'
            font-weight: 400
            font-size: 11rem
            margin-top: 5rem
    
    .initialTextBox
        display: flex
        flex-direction: column
        width: 100%
        :first-child
            font-family: 'Inter'
            font-weight: 400
            font-size: 30px
            text-align: center
            max-width: 70rem
            align-self: center
            margin: 20px 20px
        :last-child
            width: 21rem
            height: .5rem
            background: rgba(50, 34, 29, 1)
            align-self: center
            margin-top: 4rem

    .contentBox
        margin-top: 8rem
        display: flex
        padding-bottom:  12.7rem
        justify-content: center

    .typeServices
        .ButtonSelect
            background: $details-color
            border: 2px solid rgba(30, 30, 30, 1)
            &:hover
                background: $details-color

        p
            width: 26rem
            height: 7rem
            background: rgba(30, 30, 30, 1)
            border-radius: 8px
            margin: 0px 22px 10px 0px
            display: flex
            justify-content: center
            align-items: center
            font-family: 'Josefin Sans'
            font-weight: 300
            font-size: 3.2rem
            text-align: center
            cursor: pointer
            &:hover
                background: rgba(75, 54, 18, 0.5)
                border: 2px solid rgba(30, 30, 30, 1)
            
    .box
        width: 80rem
        height: 55rem
        background: rgba(30, 30, 30, 1)
        border-radius: 8px
        position: relative
        display: flex
        justify-content: center
        align-items: center

    .circle
        width: 3rem
        height: 3rem
        border-radius: 100%
        background: rgba(196, 149, 69, 1)
        position: absolute
        right: 0
        top: 0
        margin: 20px 20px 0px 0px

    .textInvisible
        display: none

    .textBox
        font-family: 'Josefin Sans'
        font-weight: 300
        font-size: 36px
        max-width: 612px
        margin: 10px 40px
      
    .navBar
        width: 100%
        height: 15.4rem
        background: rgba(50, 34, 29, 1)
        display: flex
        justify-content: center

    .contentNavBar
        width: 70%
        display: flex
        justify-content: space-between
        align-items: center
        p
            font-family: 'Inter'
            font-weight: 300
            font-size: 22px
            margin-top: 10px
            margin-right: 50px


@media (max-width:1150px)
    #pageServices
        .contentBox
            margin-top: 8rem
        
        .box
            width: 700px
            height: 55rem
        
        .initialTextBox
            :first-child
                font-size: 32px


@media (max-width: 1050px)
    #pageServices
        .typeServices
            p
                width: 20rem
                font-size: 28px
    
        .box
            width: 600px


@media (max-width: 850px)
    #pageServices
        .typeServices
            p
                width: 14rem
                border-radius: 8px
                margin: 0px 20px 10px 0px
                font-size: 24px

        .initialTextBox
            :first-child
                font-size: 28px
        .box
            width: 450px
            

        .textBox
            margin: 0px 69px 0px 69px 
            font-size: 30px
                

@media (max-width:800px) 
    @keyframes Header    
        0%
            transform: translate(calc(100%))
    #pageServices
        .navigationCascata
            animation-name: Header
            animation-duration: 1s
            transition-property: display
            transition-duration: 10s 
            position: absolute
            width: 100%
            height: 1000%
            z-index: 1
            left:0
            bottom: 0
            top: 0
            right: 0
            background: #aa813a
            display: flex
            flex-direction: column
            align-items: center
            justify-content: flex-start
            :first-child
                margin-top: 150px
            a      
                margin-top: 30px
                border-radius: 20px
                text-align: center
                border: 2px solid #fff
                width: 50%
                font-weight: 500
                font-size: 2rem
                font-family: 'Inter'
                text-decoration: none
                padding: 2rem 0
                &:hover
                    background: rgba(230, 176, 82, 0.5)

            .buttonLogin
                margin-top: 30px
                border-radius: 20px
                text-align: center
                border: 2px solid #fff
                width: 50%
                font-weight: 500
                font-size: 2rem
                font-family: 'Inter'
                text-decoration: none
                padding: 2rem 0
                &:hover
                    background: rgba(230, 176, 82, 0.5)
        .hamburguerClose
            transition: 0.4s
            z-index: 2
            cursor: pointer
            width: 40px
            height: 5px 
            border-radius: .8rem
            background: #fff
            border: 0 
            position: absolute
            transform: rotate(45deg)
            right: 0
            margin-right: 10%
            display: flex
            justify-self: center
            align-self: center
            &::before
                transition: 0.4s
                content: ""
                position: absolute
                width: 40px
                height: 5px 
                left:0
                transform: rotate(90deg)
                border-radius: .8rem
                background: #fff


        .hamburguer
            transition: 0.4s
            z-index: 2
            cursor: pointer
            width: 40px
            height: 5px 
            border-radius: .8rem
            background: #fff
            border: 0 
            position: absolute
            right: 0
            margin-right: 10%
            display: flex
            justify-self: center
            align-self: center
            &::before
                transition: 0.4s
                content: ""
                position: absolute
                bottom: -10px
                width: 40px
                height: 5px 
                left:0
                border-radius: .8rem
                background: #fff
            &::after
                content: ""
                position: absolute
                bottom: -20px
                width: 40px
                height: 5px 
                left:0
                border-radius: .8rem
                background: #fff    
        .navigation
            display: none

@media (max-width: 650px)
    #pageServices
        .typeServices
            p
                width: 12rem
                height: 6rem
                margin: 0px 20px 10px 0px
                font-size: 22px
        .initialTextBox
            :first-child
                font-size: 26px       
        
        .box
            width: 380px
            height: 48rem       

        .textBox
            font-size: 26px

        .contentNavBar
            p
                font-size: 18px
            :last-child
                width: 100px

@media (max-width: 550px)
    #pageServices
        .imageServices
            height: 350px
        .textServices
            :first-child
                font-size: 9rem
        .initialTextBox
            :first-child
                font-size: 24px
            :last-child
                width: 16rem

        .typeServices
            p
                width: 10rem
                height: 5rem
                font-size: 19px

        .contentBox
            margin-top: 8rem
            padding-bottom:  8rem
        
        .box
            width: 300px
            height: 41rem

        .circle
            width: 2.5rem
            height: 2.5rem

        .textBox
            font-size: 22px
            margin: 0px 30px 0px 30px 

        .navBar
            height: 12rem

        .contentNavBar
            p
                font-size: 16px
            :last-child
                width: 90px


@media (max-width: 450px)
    #pageServices 
        .imageServices
            height: 300px

        .textServices
            :first-child
                font-size: 6rem

        .initialTextBox
            :first-child
                font-size: 20px
            :last-child
                width: 14rem

        .contentBox
            margin-top: 5rem
            padding-bottom:  5rem

        .box
            width: 225px
            height: 34rem

        .typeServices
            p
                width: 8rem
                height: 4rem
                font-size: 16px

        .circle
            width: 2rem
            height: 2rem

        .textBox
            font-size: 18px
        
        .navBar
            height: 10rem

        .contentNavBar
            p
                font-size: 11px
            :last-child
                width: 70px

@media (max-width: 350px)
    #pageServices    
        .textServices
            :first-child
                font-size: 5rem
        .initialTextBox
            :first-child
                font-size: 16px
            :last-child
                width: 12rem

        .box
            width: 175px
            height: 34rem

        .typeServices
            p
                width: 7rem
                height: 4rem
                font-size: 14px

        .textBox
            font-size: 15px
            margin: 0px 20px 0px 20px

        .navBar
            height: 8rem

        .contentNavBar
            p
                font-size: 8px
            :last-child
                width: 70px