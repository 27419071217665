
$base-color: #C49545
.body
    display: flex
    justify-content: center
    width: 100%
    height: 100%   
    background-color: $base-color
    position: relative

.pages
    display: flex
    flex-direction: column
    max-width: 1920px
    width: 100%

.align
    width: 70%
    align-self: center

    