$base-color: #C49545
$details-color: #D6AF4F

#contact
    margin-top:8.7rem
    background-image: url('../../image/imageContact.png')
    background-repeat: no-repeat
    background-position: center
    width: 100%
    height: 890px

    .config
        display: flex
        justify-content: center

    .contentCondacs
        margin-top: 2rem 
        display: flex
        width: 134.4rem
        justify-content: space-between

    .contentContact
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        p
            font-size: 4.8rem
            font-weight: 400   
            font-family: "Inter" 
            height: 6rem    
        form
            margin-top: 5rem
            display: flex
            flex-direction: column

    .windowSocial
        display: none

    .inputs
        background: none
        padding: 1rem
        outline: 0
        border: none
        border-bottom: 1px solid $base-color
        max-width: 52.7rem
        max-height: 5rem
        margin: 2rem
        font-family: 'Inter'
        font-weight: 300
        font-size: 2.6rem
        color: white
        &::placeholder
                color: white

    .enviar
        margin-top: 7.5rem
        width: 21.1rem
        height: 5.8rem
        border: 3px solid $details-color
        border-radius: .8rem
        align-self: center
        background: none
        cursor: pointer
        &:hover
            background: rgba(164, 125, 59, 0.5)
        :first-child
            font-size: 2.4rem
            font-weight: 500
            font-size: 'Inter'
        
    .contentSocial, .contenteSocialCell
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        h3
            font-size: 4.8rem
            font-weight: 400   
            font-family: "Inter" 
            position: relative
        p
            margin-top:1rem  
            font-family: 'Josefin Sans'
            font-weight: 300
            font-size: 2.4rem
        
        h2
            font-family: 'Inter'
            font-weight: 300
            font-size: 3rem
            margin-left: 1rem
            height: 100%

    .details
        width: 200px
        height: .5rem
        background: white

    .networkSocial
        width: 100px
        margin-top:4rem
        display: flex
        justify-content: space-between
        
    .whatsapp
        display: flex
        align-items: center
        margin: 3rem 0 3rem 0
        text-decoration: none

        h3 
            color: $base-color

    .navBar
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        position: absolute
        width: 100%
        bottom: 0
        &::before
            content: ""
            width: 100%
            height: .2rem
            background: $base-color
        
    .contentNavBar
        display: flex
        justify-content: space-between
        align-items: center
        width: 80%
        margin: 20px
        p
            align-items: center
            display: flex
            font-family: 'Inter'
            font-weight: 300
            font-size: 2.4rem
            margin-right: 10px

    

@media (max-width: 900px)
    #contact
        height: 80rem

        .contentSocial
            display: none 
        
        .contentCondacs
            justify-content: center
            position: relative
        
        .contentContact
            align-items: center
            display: flex
            flex-direction: column
            width: 100%
            form
                margin-top: 10px
        
        .enviar
            align-self: center

        .windowSocial
            justify-content: space-between
            display: flex
            margin-top: 4rem

        .activeModal
            cursor: pointer

        .divWhatsapp
            background: none
            border: none
            justify-self: flex-start
            align-self: flex-start
                    
        .middleIcon
            padding: 0 5px

        .contenteSocialCell
            width: 100%
            height: 100%
            position: absolute
            background: rgba(0, 0, 0, 0.2)
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            border-radius: 20px
            backdrop-filter: blur(10px)
            h3
                margin-top: 40px
                font-size: 4rem

        .telefone
            margin-right: 20px

            
        .modal
            padding: 2rem 4rem
            background: $base-color
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            border-radius: 20px
            position: relative
        
        .buttonClose
            cursor: pointer
            width: 40px
            height: 5px 
            border-radius: .8rem
            background: #fff
            position: absolute
            transform: rotate(45deg)
            right: 3%
            top: 10%
            display: flex
            justify-self: center
            align-self: center
            &::before
                content: ""
                position: absolute
                width: 40px
                height: 5px 
                left:0
                transform: rotate(90deg)
                border-radius: .8rem
                background: #fff

        .networkSocial
            display: none
        h3
            font-family: 'Inter'
            font-size: 3rem
        p   
            font-family: 'josefin Sans'
            margin: 20px 0 0 0
            font-size: 2rem

        .whatsapp
            margin: 0 0 0 0
            h2
                font-family: 'Inter'
                margin-left: 10px
                font-size: 3rem

        .contentNavBar
            p
                font-size: 1.3rem
                max-width: 240px
            :last-child
                width: 90px
  

@media (max-width: 615px)
    #contact
        height: 68rem

        .contentCondacs
            margin-top: 5rem 

        .contentContact
            p
                font-size: 3.5rem
                height: 40px

            form
                margin-top: 10px
                width: 300px

        .inputs
            font-size: 2rem

        .enviar
            margin-top: 3.5rem
            width: 12rem
            align-self: center
            height: 5rem
                    

        .contenteSocialCell
            h3
                font-size: 2.5rem
            p   
                font-size: 2rem
        .whatsapp
            svg 
                width: 30px
            h2
                font-size: 2rem
        .buttonClose
            width: 25px
            height: 5px 
            &::before
                width: 25px
                height: 5px
        .details
            width: 100px
            height: .5rem
            background: white


    
        
     

@media (max-width: 450px)
    #contact
        .contentContact
            p
                font-size: 2.5rem
                height: 30px
            form
                width: 250px

        .details
            width: 100px

        .enviar
            margin-top: 3rem
            width: 10rem
            align-self: center
            height: 4rem
            border: 3px solid $details-color
            border-radius: .8rem
            margin-left: 2rem
            background: none
            :first-child
                font-size: 1.5rem
                font-weight: 500
                font-size: 'Inter'

        .contentNavBar
            p
                font-size: 1.2rem
                max-width: 240px
            :last-child
                width: 80px

    