$base-color: #C49545
$details-color: #D6AF4F

.background
    background: url('../../image/imageHeader.png')
    max-width: 192rem
    width: 100%
    height: 648px
    background-position: center
    background-repeat: no-repeat
    display: flex
    justify-content: center

    .config
        width: 70%
        
    .header 
        display: flex  
        justify-content: space-between  

        :first-child
            z-index: 50

    .navigation, .navigationCascata
        display: flex
        align-items: center
        width: 80%
        justify-content: space-between 
        a   
            font-weight: 500
            font-size: 2rem
            font-family: 'Inter'
            text-decoration: none
            padding: 2rem
        .buttonLogin
            border: 1px solid $details-color
            border-radius: .8rem
            padding: .5rem 3rem .5rem 3rem
            background: none
            font-weight: 500
            font-size: 2rem
            font-family: 'Inter'
            &:hover
                background: rgba(164, 125, 59, 0.5)
                
    .nameCompany 
        margin-top: 5rem
        :first-child
            font-size: 10.5rem
            font-family: 'Josefin Sans'
            font-weight: 600
        :last-child    
            font-size: 9rem
            font-family: 'Josefin Sans'
            font-weight: 300

    .buttonContato 
        font-size: 2rem
        background: $base-color
        border-radius: .8rem
        padding: 1.3rem 3rem 1.3rem 3rem    
        border: none    
        margin-top: 5.7rem
        cursor: pointer
        border: 2px solid rgba(255, 255, 255,)
        &:hover
            background: rgba(164, 125, 59, 0.5)

    .hamburguer
        display: none

    .hamburguerClose
        display: none

@media (max-width: 1440px)
    .background
        height: 500px

        .nameCompany 
            margin-top: 5rem
            :first-child
                font-size: 8rem
                font-family: 'Josefin Sans'
                font-weight: 600
            :last-child    
                font-size: 7rem
                font-family: 'Josefin Sans'
                font-weight: 300

        .buttonContato 
            padding: 1rem 2rem 1rem 2rem    
    

@media (max-width:800px) 
    @keyframes Header    
        0%
            transform: translate(calc(100%))

    .background
        .navigationCascata
            animation-name: Header
            animation-duration: 1s
            transition-property: display
            transition-duration: 10s 
            position: absolute
            width: 100%
            height: 1000%
            z-index: 1
            left:0
            bottom: 0
            top: 0
            right: 0
            background: #aa813a
            display: flex
            flex-direction: column
            align-items: center
            justify-content: flex-start
            .buttonsNavigation
                margin-top: 150px

            a      
                margin-top: 30px
                border-radius: 20px
                text-align: center
                border: 2px solid #fff
                width: 50%
                font-weight: 500
                font-size: 2rem
                font-family: 'Inter'
                text-decoration: none
                padding: 2rem 0
                &:hover
                    background: rgba(230, 176, 82, 0.5)
            
            .buttonLogin
                margin-top: 30px
                border-radius: 20px
                text-align: center
                border: 2px solid #fff
                width: 50%
                font-weight: 500
                font-size: 2rem
                font-family: 'Inter'
                text-decoration: none
                padding: 2rem 0
                &:hover
                    background: rgba(164, 125, 59, 0.5)

        .hamburguerClose
            transition: 0.4s
            z-index: 2
            cursor: pointer
            width: 40px
            height: 5px 
            border-radius: .8rem
            background: #fff
            border: 0 
            position: absolute
            transform: rotate(45deg)
            right: 0
            margin-right: 10%
            display: flex
            justify-self: center
            align-self: center
            &::before
                transition: 0.4s
                content: ""
                position: absolute
                width: 40px
                height: 5px 
                left:0
                transform: rotate(90deg)
                border-radius: .8rem
                background: #fff

        .hamburguer
            transition: 0.4s
            z-index: 2
            cursor: pointer
            width: 40px
            height: 5px 
            border-radius: .8rem
            background: #fff
            border: 0 
            position: absolute
            right: 0
            margin-right: 10%
            display: flex
            justify-self: center
            align-self: center
            
            &::before
                transition: 0.4s
                content: ""
                position: absolute
                bottom: -10px
                width: 40px
                height: 5px 
                left:0
                border-radius: .8rem
                background: #fff
            &::after
                content: ""
                position: absolute
                bottom: -20px
                width: 40px
                height: 5px 
                left:0
                border-radius: .8rem
                background: #fff    
                
        .navigation
            display: none

@media (max-width:640px)  
    .background
        .nameCompany
            :first-child
                font-size: 8rem
            :last-child
                font-size: 5rem


@media (max-width:500px)  
    .background
        .nameCompany
            :first-child
                font-size: 6rem
            :last-child
                font-size: 4rem

@media (max-width:400px)  
    .background
        .nameCompany
            :first-child
                font-size: 4.8rem
            :last-child
                font-size: 4rem


            
        




    
    

    


    


